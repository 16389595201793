import React, { FC, useMemo } from 'react';
import { ConceptualMeasurementObject } from '@components/EntityDrawers/constants/empty-objects';
import { getLabels } from '@components/EntityDrawers/drawers/Conceptual/field-labels';
import { ConceptualKeys } from '@components/EntityDrawers/drawers/Conceptual/keys';
import useSourceNode from '@components/EntityDrawers/hooks/useSourceNode';
import ControlledInputGroups, {
  InputsBlockProps,
} from '@components/Inputs/controllers/ControlledInputGroups';
import ControlledTextField from '@components/Inputs/controllers/ControlledTextField';
import { ConceptualSubLayerTypes } from '@constants/canvas/layers';
import { useProject } from '@context/Project/ProjectProvider';
import { getInputGroupsProps } from '@utils/helpers';

const MeasurementInputs: FC<InputsBlockProps> = ({ createName, viewOnly }) => {
  const activeNode = useSourceNode();

  const {
    measurementApproachesLabel,
    metricTypeLabel,
    metricExpectOutputsLabel,
    performanceTargetLabel,
    primaryThresholdLabel,
    secondaryThresholdLabel,
  } = useMemo(
    () => getLabels(activeNode?.parentNode as ConceptualSubLayerTypes),
    [activeNode?.parentNode],
  );

  return (
    <>
      <ControlledTextField
        name={createName('approach')}
        label={measurementApproachesLabel}
        placeholder="Measurement approaches"
        disabled={viewOnly}
        multiline
      />

      <ControlledTextField
        name={createName('metric_type')}
        label={metricTypeLabel}
        placeholder="Metric type"
        disabled={viewOnly}
        multiline
      />

      <ControlledTextField
        name={createName('metric_output')}
        label={metricExpectOutputsLabel}
        placeholder="Metric expect outputs"
        disabled={viewOnly}
        multiline
      />

      <ControlledTextField
        name={createName('target')}
        label={performanceTargetLabel}
        placeholder="Performance target"
        disabled={viewOnly}
        multiline
      />

      <ControlledTextField
        name={createName('primary_threshold')}
        label={primaryThresholdLabel}
        placeholder="Primary threshold"
        disabled={viewOnly}
        multiline
      />

      <ControlledTextField
        name={createName('secondary_threshold')}
        label={secondaryThresholdLabel}
        placeholder="Secondary threshold"
        disabled={viewOnly}
        multiline
      />
    </>
  );
};

const MeasurementGroup: FC = () => {
  const { viewOnly } = useProject();

  return (
    <ControlledInputGroups
      name={ConceptualKeys.Measurement}
      viewOnly={viewOnly}
      groupObject={ConceptualMeasurementObject}
      {...getInputGroupsProps('measurement')}
    >
      {MeasurementInputs}
    </ControlledInputGroups>
  );
};

export default MeasurementGroup;
