import { FunctionComponent, SVGProps } from 'react';
import { InsightKeys } from '@constants/entities/insights';

export enum Tabs {
  Dependency = 'dependency',
  FocusOn = 'focusOn',
  Maturity = 'maturity',
  ModelEvolution = 'modelEvolution',
  Completeness = 'completeness',
  KeyControls = 'keyControls',
  CIA = 'cia',
  Default = 'default',
}

export type InsightsTab = {
  id: Tabs;
  label: string;
  description: string;
  Icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  key: InsightKeys;
};
