import { NameObjectiveObject } from '@components/EntityDrawers/constants/empty-objects';
import { CommonKeys } from '@components/EntityDrawers/constants/keys';
import { TransformContext } from '@components/EntityDrawers/hooks/useTransformContext';
import { EntityValidationStatus } from '@constants/canvas/layers';
import { WideDomainKey } from '@constants/entities/ui';
import {
  MaxDescriptionLength,
  NameObjectivesYup,
  YupDayJs,
  YupMaturity,
  YupString,
  YupTargetMaturity,
  YupTimelineStartDate,
  YupValidationStatus,
} from '@constants/validation';
import { NameObjective, NodeDTO } from '@store/services/nodes/types';
import { stringToDayjs } from '@utils/drawers';
import { getEntitiesArray, withId } from '@utils/helpers';
import dayjs from 'dayjs';
import * as yup from 'yup';

export const commonYups = {
  [CommonKeys.Name]: YupString().required('Entity name is required'),
  [CommonKeys.Owner]: YupString(),
  [CommonKeys.Domain]: yup.string(),
  [CommonKeys.Description]: YupString(1, MaxDescriptionLength),
  [CommonKeys.ValidationStatus]: YupValidationStatus(),
  [CommonKeys.DateCreated]: YupDayJs().required(),
  [CommonKeys.DateReviewed]: YupDayJs().nullable(),
  [CommonKeys.QnousRef]: yup.array().of(yup.object()),
  [CommonKeys.NistRef]: yup.array().of(yup.object()),
  [CommonKeys.OperationalStatus]: yup.string().nullable(),
  [CommonKeys.TimelineStartDate]: YupTimelineStartDate(),
  [CommonKeys.TimelineEndDate]: YupDayJs().nullable(),
  [CommonKeys.Metrics]: NameObjectivesYup(),
  [CommonKeys.CurrentMaturity]: YupMaturity(),
  [CommonKeys.TargetMaturity]: YupTargetMaturity(),
  [CommonKeys.OperationalModel]: YupString(),
  [CommonKeys.OperationalEffectiveness]: YupString(),
  [CommonKeys.DesignEffectiveness]: YupString(),
};

export const commonDefaults = {
  [CommonKeys.Name]: '',
  [CommonKeys.Owner]: '',
  [CommonKeys.Domain]: WideDomainKey,
  [CommonKeys.Description]: '',
  [CommonKeys.ValidationStatus]: EntityValidationStatus.Draft,
  [CommonKeys.DateReviewed]: null,
  [CommonKeys.DateCreated]: dayjs(),
  [CommonKeys.QnousRef]: [],
  [CommonKeys.NistRef]: [],
  [CommonKeys.OperationalStatus]: null,
  [CommonKeys.TimelineStartDate]: null,
  [CommonKeys.TimelineEndDate]: null,
  [CommonKeys.Metrics]: [withId(NameObjectiveObject)],
  [CommonKeys.CurrentMaturity]: null,
  [CommonKeys.TargetMaturity]: null,
  [CommonKeys.OperationalModel]: '',
  [CommonKeys.OperationalEffectiveness]: '',
  [CommonKeys.DesignEffectiveness]: '',
};

export const commonTransformers: Record<
  CommonKeys,
  (node: NodeDTO<any>, context: TransformContext) => any
> = {
  [CommonKeys.Name]: ({ name }) => name,
  [CommonKeys.Owner]: ({ requirement_owner }) => requirement_owner ?? '',
  [CommonKeys.Domain]: ({ domain_id }) => domain_id ?? WideDomainKey,
  [CommonKeys.Description]: ({ description }) => description ?? '',
  [CommonKeys.ValidationStatus]: ({ valid_status }) => valid_status,
  [CommonKeys.DateCreated]: ({ created_at }) => dayjs(created_at),
  [CommonKeys.DateReviewed]: ({ reviewed_at }) =>
    reviewed_at ? dayjs(reviewed_at) : null,
  [CommonKeys.QnousRef]: ({ qnous_ref }, { qnousMap }) => {
    return (
      qnous_ref?.map((id) => ({
        value: id,
        label: qnousMap.get(id) ?? '',
      })) ?? []
    );
  },
  [CommonKeys.NistRef]: ({ nist }, { nistMap }) => {
    return (
      nist?.map((id) => ({
        value: id,
        label: nistMap.get(id) ?? '',
      })) ?? []
    );
  },
  [CommonKeys.OperationalStatus]: ({ meta_data: { operational_status } }) =>
    operational_status ?? '',
  [CommonKeys.TimelineStartDate]: ({ meta_data: { start_date } }) =>
    stringToDayjs(start_date),
  [CommonKeys.TimelineEndDate]: ({ meta_data: { end_date } }) =>
    stringToDayjs(end_date),
  [CommonKeys.Metrics]: ({ meta_data: { metric } }) =>
    getEntitiesArray<NameObjective>(metric, NameObjectiveObject),
  [CommonKeys.CurrentMaturity]: ({ meta_data: { current_maturity } }) =>
    current_maturity ?? '',
  [CommonKeys.TargetMaturity]: ({ meta_data: { target_maturity } }) =>
    target_maturity ?? '',
  [CommonKeys.OperationalModel]: ({ meta_data: { operational_model } }) =>
    operational_model ?? '',
  [CommonKeys.OperationalEffectiveness]: ({
    meta_data: { operational_effectiveness },
  }) => operational_effectiveness ?? '',
  [CommonKeys.DesignEffectiveness]: ({ meta_data: { design_effectiveness } }) =>
    design_effectiveness ?? '',
};
