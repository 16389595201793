import React, { FC } from 'react';
import { FormProvider } from 'react-hook-form';
import { Node } from 'reactflow';
import ConfirmationModals from '@components/EntityDrawers/components/ConfirmationModals';
import ProjectRightDrawer, {
  ProjectRightDrawerProps,
} from '@components/EntityDrawers/components/ProjectRightDrawer';
import useNodeDrawer, {
  EntityDrawerOptions,
} from '@components/EntityDrawers/hooks/useNodeDrawer';
import { DrawerProvider } from '@components/EntityDrawers/providers/DrawerProvider';
import { SubLayerTypes } from '@constants/canvas/layers';
import { ProjectDrawerTypes } from '@constants/entities/drawers';

export type DrawerWrapperProps<T, F = {}> = {
  type: T;
  drawerProps?: Pick<ProjectRightDrawerProps, 'tab' | 'headChildren'>;
  canSave?: (
    values: F,
    editMode: boolean,
    activeNode: Node | undefined,
  ) => PromiseLike<boolean>;
};

export type DrawerContentProps = { tab?: number };
export type DrawerSettings<FormValuesType, MetaType> = Omit<
  EntityDrawerOptions<FormValuesType, MetaType>,
  'type'
>;

const withDrawer = <FormValuesType, MetaType, Type extends ProjectDrawerTypes>(
  DrawerContent: FC<DrawerContentProps>,
  options: DrawerSettings<FormValuesType, MetaType>,
) => {
  return ({
    type,
    drawerProps,
    canSave,
  }: DrawerWrapperProps<Type, FormValuesType>) => {
    const {
      form,
      handleSave,
      isSaveDisabled,
      isIncompleteMessageShown,
      isReviewRequired,
      handleClose,
      drawerTitle,
      badge,
      open,
      editMode,
      modalsProps,
      activeNode,
    } = useNodeDrawer<FormValuesType, MetaType>({ type, ...options });

    if (!open) return null;

    const handleSaveClick = async () => {
      const canProceed = await canSave?.(
        form.getValues(),
        editMode,
        activeNode,
      );

      if (canProceed || !canSave) await handleSave();
    };

    return (
      <DrawerProvider
        value={{
          subLayer: (activeNode?.parentNode ?? '') as SubLayerTypes,
          editMode,
          isIncomplete: isIncompleteMessageShown,
          isReviewRequired,
        }}
      >
        <ConfirmationModals {...modalsProps} />

        <FormProvider {...form}>
          <ProjectRightDrawer
            title={drawerTitle}
            onClose={handleClose}
            disabledSave={isSaveDisabled}
            onSave={handleSaveClick}
            badge={badge}
            {...drawerProps}
          >
            <DrawerContent tab={drawerProps?.tab} />
          </ProjectRightDrawer>
        </FormProvider>
      </DrawerProvider>
    );
  };
};

export default withDrawer;
