import {
  CommonKeys,
  CoreRequiredKeys,
  LogicPhysicalKeys,
} from '@components/EntityDrawers/constants/keys';
import {
  defaultValues,
  LogicalFormValues,
  validationSchema,
} from '@components/EntityDrawers/drawers/Logical/form';
import { LogicalKeys } from '@components/EntityDrawers/drawers/Logical/keys';
import { DrawerSettings } from '@components/EntityDrawers/hocs/withDrawer';
import { ProjectDrawerTypes } from '@constants/entities/drawers';
import { LogicalMetaData } from '@store/services/nodes/types';
import {
  checkValuesIncomplete,
  prepareBasicLogicPhysicalData,
  transformCommonKeys,
  transformPolicyFormValuesToMetaData,
  transformPolicyToFormValues,
} from '@utils/drawers';

export const LogicalSettings: DrawerSettings<
  LogicalFormValues,
  LogicalMetaData
> = {
  editType: ProjectDrawerTypes.EditLogicalEntity,
  defaultValues,
  validationSchema,

  checkIncompleteFields: checkValuesIncomplete<LogicalFormValues>(
    ...CoreRequiredKeys,
    CommonKeys.QnousRef,
    CommonKeys.NistRef,
  ),

  transformNodeDtoToFormValues: (nodeDto, context) => ({
    ...transformCommonKeys<LogicalFormValues, LogicalMetaData>(
      ...LogicPhysicalKeys,
    )(nodeDto, context),

    [LogicalKeys.Policies]: transformPolicyToFormValues(
      nodeDto.meta_data.policy,
    ),
  }),

  transformMetaDataToRequestBody: ({ policies, ...metadata }) =>
    ({
      ...prepareBasicLogicPhysicalData(metadata),
      policy: transformPolicyFormValuesToMetaData(policies),
    }) as LogicalMetaData,
};
