import { createContext, useContext } from 'react';
import {
  ConceptualSubLayerTypes,
  SubLayerTypes,
} from '@constants/canvas/layers';

type DrawerContextType = {
  isIncomplete: boolean | undefined;
  isReviewRequired: boolean;
  editMode: boolean;
  subLayer: SubLayerTypes;
};

const DrawerContext = createContext<DrawerContextType>({
  isIncomplete: false,
  isReviewRequired: false,
  editMode: false,
  subLayer: ConceptualSubLayerTypes.SecurityAttributes,
});

export const useDrawerContext = () => {
  const context = useContext(DrawerContext);

  if (!context) {
    throw new Error('useDrawerContext must be used within DrawerProvider');
  }

  return context;
};

export const DrawerProvider = DrawerContext.Provider;
