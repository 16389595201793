export enum InsightKeys {
  Dependency = 'dependency',
  FocusOn = 'focus-on',
  Maturity = 'maturity',
  ModelEvolution = 'model-evolution',
  Completeness = 'completeness',
  KeyControls = 'key-controls',
  CIA = 'cia',
}

export const ReadableInsights = {
  [InsightKeys.Dependency]: 'Dependency',
  [InsightKeys.FocusOn]: 'Focus On',
  [InsightKeys.Maturity]: 'Maturity',
  [InsightKeys.ModelEvolution]: 'Model Evolution',
  [InsightKeys.Completeness]: 'Completeness',
  [InsightKeys.KeyControls]: 'Key Controls',
  [InsightKeys.CIA]: 'Change Initiatives Assurance',
};

export const getInsightOptions = (keys: InsightKeys[]) =>
  keys.map((key) => ({
    value: key,
    label: ReadableInsights[key],
  }));
