import React, { FC } from 'react';
import { RuleObject } from '@components/EntityDrawers/constants/empty-objects';
import ControlGroup from '@components/EntityDrawers/drawers/Physical/inputs/ControlGroup';
import { PhysicalKeys } from '@components/EntityDrawers/drawers/Physical/keys';
import ControlledInputGroups, {
  InputsBlockProps,
} from '@components/Inputs/controllers/ControlledInputGroups';
import ControlledLinkInput from '@components/Inputs/controllers/ControlledLinkInput';
import ControlledTextField from '@components/Inputs/controllers/ControlledTextField';
import { useProject } from '@context/Project/ProjectProvider';
import { getInputGroupsProps } from '@utils/helpers';

const TechInstructionsInputs: FC<InputsBlockProps> = ({
  createName,
  viewOnly,
}) => (
  <>
    <ControlledTextField
      name={createName('name')}
      label="Tech instruction name"
      placeholder="Tech instruction name"
      disabled={viewOnly}
      multiline
    />

    <ControlGroup name={createName(PhysicalKeys.Control)} />

    <ControlledLinkInput
      name={createName('link')}
      label="Tech instruction link"
      placeholder="Tech instruction link"
      disabled={viewOnly}
      multiline
    />
  </>
);

const TechInstructionsGroup: FC = () => {
  const { viewOnly } = useProject();

  return (
    <ControlledInputGroups
      name={PhysicalKeys.TechInstructions}
      viewOnly={viewOnly}
      groupObject={RuleObject}
      {...getInputGroupsProps('tech instruction')}
    >
      {TechInstructionsInputs}
    </ControlledInputGroups>
  );
};

export default TechInstructionsGroup;
