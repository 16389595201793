import React, { FC } from 'react';
import { RuleObject } from '@components/EntityDrawers/constants/empty-objects';
import ControlGroup from '@components/EntityDrawers/drawers/Physical/inputs/ControlGroup';
import { PhysicalKeys } from '@components/EntityDrawers/drawers/Physical/keys';
import ControlledInputGroups, {
  InputsBlockProps,
} from '@components/Inputs/controllers/ControlledInputGroups';
import ControlledLinkInput from '@components/Inputs/controllers/ControlledLinkInput';
import ControlledTextField from '@components/Inputs/controllers/ControlledTextField';
import { useProject } from '@context/Project/ProjectProvider';
import { getInputGroupsProps } from '@utils/helpers';

const StandardsInputs: FC<InputsBlockProps> = ({ createName, viewOnly }) => (
  <>
    <ControlledTextField
      name={createName('name')}
      label="Standard name"
      placeholder="Standard name"
      disabled={viewOnly}
      multiline
    />

    <ControlGroup name={createName(PhysicalKeys.Control)} />

    <ControlledLinkInput
      name={createName('link')}
      label="Standard link"
      placeholder="Standard link"
      disabled={viewOnly}
      multiline
    />
  </>
);

const StandardsGroup: FC = () => {
  const { viewOnly } = useProject();

  return (
    <ControlledInputGroups
      name={PhysicalKeys.Standards}
      viewOnly={viewOnly}
      groupObject={RuleObject}
      {...getInputGroupsProps('standard')}
    >
      {StandardsInputs}
    </ControlledInputGroups>
  );
};

export default StandardsGroup;
