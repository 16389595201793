import React, { FC } from 'react';
import { RuleObject } from '@components/EntityDrawers/constants/empty-objects';
import { useDrawerContext } from '@components/EntityDrawers/providers/DrawerProvider';
import ControlledInputGroups, {
  InputsBlockProps,
} from '@components/Inputs/controllers/ControlledInputGroups';
import ControlledTextField from '@components/Inputs/controllers/ControlledTextField';
import { PhysicalSubLayerTypes } from '@constants/canvas/layers';
import { useProject } from '@context/Project/ProjectProvider';
import { useDebounce } from '@hooks/useDebounce';
import { getInputGroupsProps } from '@utils/helpers';

const titles = {
  [PhysicalSubLayerTypes.Functions]: 'Control reference',
  [PhysicalSubLayerTypes.Capabilities]: 'Control objective',
} as const;

const ControlInputs: FC<InputsBlockProps> = ({ createName, viewOnly }) => {
  const { subLayer } = useDrawerContext();

  return (
    <>
      <ControlledTextField
        name={createName('name')}
        placeholder={titles[subLayer as PhysicalSubLayerTypes]}
        disabled={viewOnly}
        multiline
      />
    </>
  );
};

type Props = { name: string };

const ControlGroup: FC<Props> = ({ name }) => {
  const { viewOnly } = useProject();
  const { subLayer } = useDrawerContext();
  const subLayerId = useDebounce(subLayer, 1000);

  return (
    <ControlledInputGroups
      name={name}
      viewOnly={viewOnly}
      groupObject={RuleObject}
      titleProps={{ variant: 'h5' }}
      {...getInputGroupsProps(
        titles[subLayerId as PhysicalSubLayerTypes]?.toLowerCase(),
      )}
    >
      {ControlInputs}
    </ControlledInputGroups>
  );
};

export default ControlGroup;
