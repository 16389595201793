import {
  NameLinkObject,
  ProgressNoteObject,
} from '@components/EntityDrawers/constants/empty-objects';
import { CommonKeys } from '@components/EntityDrawers/constants/keys';
import { InitiativeKeys } from '@components/EntityDrawers/drawers/Initiative/keys';
import {
  InitiativeDeliveryStatus,
  InitiativeTypes,
} from '@constants/canvas/layers';
import { ShapeType } from '@constants/types';
import {
  AmountYup,
  NameLinksYup,
  ProgressNoteYup,
  YupString,
} from '@constants/validation';
import { Name, NameLink } from '@store/services/nodes/types';
import { generateSchema, getDefs, getYups } from '@utils/drawers';
import { withId } from '@utils/helpers';
import * as yup from 'yup';

export type InitiativeFormValues = yup.InferType<typeof validationSchema> & {
  [InitiativeKeys.ProgressNotes]: Name[];
  [InitiativeKeys.Document]: NameLink[];
};

export const InitiativeFields = [
  CommonKeys.Name,
  CommonKeys.Domain,
  CommonKeys.Owner,
  CommonKeys.DateCreated,
  CommonKeys.Description,
  CommonKeys.TimelineStartDate,
  CommonKeys.TimelineEndDate,
] as const;

const InitiativeShape = {
  [InitiativeKeys.Type]: yup.string(),
  [InitiativeKeys.ProgressStatus]: yup.string(),
  [InitiativeKeys.BusinessSponsor]: YupString(),
  [InitiativeKeys.ProgramManager]: YupString(),
  [InitiativeKeys.FundingMechanism]: yup.string(),
  [InitiativeKeys.AllocatedBudget]: AmountYup(),
  [InitiativeKeys.DeliveryStatus]: yup.string(),
  [InitiativeKeys.ProgressNotes]: ProgressNoteYup(),
  [InitiativeKeys.Document]: NameLinksYup(),
} as const;

export const validationSchema = generateSchema<
  ShapeType<typeof InitiativeFields> & typeof InitiativeShape
>({
  ...getYups(...InitiativeFields),
  ...InitiativeShape,
});

export const defaultValues: InitiativeFormValues = {
  ...getDefs(...InitiativeFields),
  [InitiativeKeys.Type]: InitiativeTypes.StrategicInitiative,
  [InitiativeKeys.ProgressStatus]: '',
  [InitiativeKeys.BusinessSponsor]: '',
  [InitiativeKeys.ProgramManager]: '',
  [InitiativeKeys.FundingMechanism]: '',
  [InitiativeKeys.AllocatedBudget]: '',
  [InitiativeKeys.DeliveryStatus]: InitiativeDeliveryStatus.OnTrack,
  [InitiativeKeys.ProgressNotes]: [withId(ProgressNoteObject)],
  [InitiativeKeys.Document]: [withId(NameLinkObject)],
};
