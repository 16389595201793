import { PhysicalKeys } from '@components/EntityDrawers/drawers/Physical/keys';
import {
  AnswerTypes,
  ContextualSubLayerTypes,
  InitiativeDeliveryStatus,
  InitiativeFundingMechanism,
  InitiativeProgressStatus,
  InitiativeTypes,
  OperationalModel,
  OperationalStatus,
  PhysicalSubLayerTypes,
  StewardsOpinion,
} from '@constants/canvas/layers';
import {
  DependencyTypes,
  RelationshipTypes,
} from '@constants/entities/relationship';
import { ISelectOption } from '@constants/entities/ui';

export const enumToOptions = (
  enumObj: Record<string, string>,
): ISelectOption[] => {
  return Object.values(enumObj).map((id) => ({ label: id, value: id }));
};

export const NameFiledTitles = {
  [ContextualSubLayerTypes.StrategicPriorities]: 'Strategic priority name',
  [ContextualSubLayerTypes.StrategicEnablers]: 'Strategic enabler name',
};

export const OwnerFiledTitles = {
  [ContextualSubLayerTypes.StrategicPriorities]: 'Strategic priority owner',
  [ContextualSubLayerTypes.StrategicEnablers]: 'Strategic enabler owner',
};

export const PhysicalNameFiledTitles = {
  [PhysicalSubLayerTypes.Capabilities]: 'Capability name',
  [PhysicalSubLayerTypes.Functions]: 'Function name',
};

export const PhysicalDescriptionFiledTitles = {
  [PhysicalSubLayerTypes.Capabilities]: 'Capability description',
  [PhysicalSubLayerTypes.Functions]: 'Function description',
};

export const PhysicalOwnerFiledTitles = {
  [PhysicalSubLayerTypes.Capabilities]: 'Capability owner',
  [PhysicalSubLayerTypes.Functions]: 'Function owner',
};

export const OperationalStatusOptions = [
  { value: OperationalStatus.Live, label: 'Live' },
  { value: OperationalStatus.InDevelopment, label: 'In development' },
  { value: OperationalStatus.Sunset, label: 'Sunset' },
];

export const OperationalModelOptions = enumToOptions(OperationalModel);
export const YesNoOptions = enumToOptions(AnswerTypes);
export const InitiativeTypeOptions = enumToOptions(InitiativeTypes);
export const StewardsOpinionOptions = enumToOptions(StewardsOpinion);

export const InitiativeProgressStatusOptions: ISelectOption[] = [
  { value: InitiativeProgressStatus.ToDo, label: 'To-do' },
  { value: InitiativeProgressStatus.InFlight, label: 'In flight' },
  { value: InitiativeProgressStatus.OnHold, label: 'On hold' },
  { value: InitiativeProgressStatus.Cancelled, label: 'Cancelled' },
  { value: InitiativeProgressStatus.Completed, label: 'Completed' },
];

export const InitiativeFundingMechanismOptions: ISelectOption[] = [
  {
    value: InitiativeFundingMechanism.BusinessAsUsual,
    label: 'Business-as-usual (BAU)',
  },
  {
    value: InitiativeFundingMechanism.FundingRequired,
    label: 'Funding required',
  },
  {
    value: InitiativeFundingMechanism.FundingSecured,
    label: 'Funding secured',
  },
];

export const InitiativeDeliveryStatusOptions: ISelectOption[] = [
  { value: InitiativeDeliveryStatus.OnTrack, label: 'On track' },
  { value: InitiativeDeliveryStatus.Delayed, label: 'Delayed' },
];

export const RelationshipTypesOptions = enumToOptions(RelationshipTypes);
export const RelationshipDependencyOptions = enumToOptions(DependencyTypes);

export const layerRulesKeys: Record<
  PhysicalSubLayerTypes,
  PhysicalKeys.TechInstructions | PhysicalKeys.Standards
> = {
  [PhysicalSubLayerTypes.Capabilities]: PhysicalKeys.Standards,
  [PhysicalSubLayerTypes.Functions]: PhysicalKeys.TechInstructions,
};
